(function ($) {

// Variables
// -----------------------------------------------------------------------------

	/* global document, window, jQuery, LazyLoad, navigator, objectFitImages, __gaTracker */
	const mdBreakpoint = 800;
	let lazyLoadInstance;
	let w = 0;

// Toggle Menu
// -----------------------------------------------------------------------------

	function toggleMenu() {
		$(".js-menu-toggle").click(function (event) {
			event.preventDefault();
			if ($(".menu-item--contact").hasClass("menu-item--open")) {
				$(".menu-item--contact").removeClass("menu-item--open");
				$(".menu-item--contact").find(".js-menu-dropdown").fadeOut();
			} else {
				$("html").toggleClass("menu-is-open");
			}
		});
	}


// Cookies & GA
// -----------------------------------------------------------------------------

	// function initCookieBar() {
	// 	$.cookieBar({
	// 		fixed: true,
	// 		declineButton: true,
	// 	});
	// }

// Toggle Dropdown Menu
// -----------------------------------------------------------------------------

	function openDropdown(parent) {
		const submenuHeight = $(".js-site-nav").outerHeight();
		let submenuOffset = $("#site-header").outerHeight() - $(document).scrollTop();
		submenuOffset = submenuOffset < 0 ? 0 : submenuOffset;
		const windowHeight = $(window).height();
		const dropdownHeight = windowHeight - (submenuOffset + submenuHeight);
		const dropdownImage = parent.find(".menu-dropdown").find("img");
		parent.find(".menu-dropdown").css("max-height", dropdownHeight);
		parent.addClass("menu-item--open");
		parent.find(".menu-dropdown").css("top", submenuHeight + "px");
		if (dropdownImage.length) {
			LazyLoad.load(dropdownImage.get(0));
		}
		$(".js-overlay").addClass("overlay--open");
		$("html").addClass("prevent-scroll");
	}

	function closeDropdown() {
		$(".js-menu-item").removeClass("menu-item--open");
		$(".js-overlay").removeClass("overlay--open");
		$("html").removeClass("prevent-scroll");
	}

	/**
	 * Close Dropdown action for links
	 */
	$(document).on('click', 'a.close-dropdown', function() {
		closeDropdown();
	});

	/**
	 * Turn on/off the dropdown
	 */
	function toggleDropdown() {

		$(".js-menu-item").off("hover");
		$(".js-dropdown-toggle").off("click");
		$(".js-menu-dropdown").css("display", "");

		if (window.matchMedia("(min-width: " + mdBreakpoint + "px)").matches === true) {
			// Applied above the medium breakpoint
			$(".js-dropdown-toggle").click(function (event) {
				event.preventDefault();
				if ($(this).parent().hasClass("menu-item--open")) {
					closeDropdown();
					$(this).blur();
				} else {
					closeDropdown();
					openDropdown($(this).parent());
					$(this).focus();
				}
			});
		} else {
			// Applied for mobile
			$(".js-dropdown-toggle").click(function (event) {
				const thisParent = $(this).parent();
				const hasClass = thisParent.hasClass("menu-item--open");
				event.preventDefault();
				$(".js-menu-item").removeClass("menu-item--open");
				$(".js-menu-dropdown").slideUp();
				if (hasClass === false) {
					if (thisParent.hasClass("menu-item--contact")) {
						thisParent.addClass("menu-item--open");
						thisParent.find(".js-menu-dropdown").fadeIn();
					} else {
						thisParent.addClass("menu-item--open");
						thisParent.find(".js-menu-dropdown").slideDown();
					}
				}
			});
		}
	}

// Toggle Answer
// -----------------------------------------------------------------------------

	function toggleAnswer() {
		$(".js-question_answer__toggle").click(function (event) {
			const hasClass = $(this).parent().hasClass("question_answer__single--open");
			event.preventDefault();
			$(".question_answer__single").removeClass("question_answer__single--open");
			$(".question_answer__single").find(".question_answer__answer").slideUp();
			if (hasClass === false) {
				$(this).parent().addClass("question_answer__single--open");
				$(this).parent().find(".question_answer__answer").slideDown();
			}
		});
	}

// Toggle Callback Form - removed for use of contact page form
// -----------------------------------------------------------------------------
/*
	function toggleCallbackForm() {
		$(".js-callback_form__toggle, .js-request-callback").click(function (event) {
			event.preventDefault();
			const formHeight = $(".callback_form").outerHeight();
			$(".callback_form").show();
			$(".callback_form").css("min-height", formHeight);
			$(".js-callback_form__visible").addClass("is-hidden");
			$(".js-callback_form__wrapper").slideDown(1000, function () {
				$(this).css("opacity", 1);
				$(".callback_form").css("min-height", "");
			});
			if ($(event.currentTarget).hasClass("js-request-callback")) {
				const navHeight = $(".js-site-nav").outerHeight();
				$("html, body").animate({
					scrollTop: ($(".callback_form").offset().top),
				}, 1500);
			}
		});
	}
*/
	
// Toggle Blue Section on Mobile
// -----------------------------------------------------------------------------

	function toggleBlueSection() {
		$(".js-blue-section-toggle").click(function (event) {
			event.preventDefault();
			$(event.currentTarget).slideUp();
			$(event.currentTarget).prev().slideDown();
		});
	}

// Scroll to Top
// -----------------------------------------------------------------------------

	function scrollToTop() {
		$(".js-back-to-top").click(function (event) {
			event.preventDefault();
			$("html, body").animate({
				scrollTop: 0,
			}, 1500);
		});
	}

// Init LazyLoad
// -----------------------------------------------------------------------------

	function initLazyLoad() {
		lazyLoadInstance = new LazyLoad({
			elements_selector: ".lazy",
		});
	}

// Init Slick
// -----------------------------------------------------------------------------

	function lazyLoadSlickImage(_this) {
		const imageToLoad = _this.find("img");
		if (imageToLoad.length) {
			LazyLoad.load(imageToLoad.get(0));
		}
	}

	function initSlick() {

		$(".top_slideshow__slider").on("init", function () {
			const firstSlide = $(".top_slideshow__slide[data-slick-index='0']");
			const secondSlide = $(".top_slideshow__slide[data-slick-index='1']");
			lazyLoadSlickImage(firstSlide);
			lazyLoadSlickImage(secondSlide);
		});

		$(".top_slideshow__slider").slick({
			dots: false,
			arrows: false,
			infinite: true,
			speed: 1000,
			fade: true,
			cssEase: "linear",
			pauseOnHover: false,
			pauseOnFocus: false,
			autoplay: true,
			autoplaySpeed: 6000,
			draggable: false,
			swipe: false,
			touchMove: false,
			accessibility: false,
		});

		$(".top_slideshow__slider").on("beforeChange", function (event, slick, currentSlide, nextSlide) {
			const parentSlide = $(".top_slideshow__slide[data-slick-index='" + (nextSlide + 1) + "']");
			lazyLoadSlickImage(parentSlide);
		});
	}

// Set Slideshow Height
// -----------------------------------------------------------------------------

	function setSlideshowHeight() {
		if ($(".top_slideshow").length && window.matchMedia("(min-width: " + mdBreakpoint + "px)").matches !== true) {
			const windowHeight = window.innerHeight;
			const headerHeight = $("#site-header").outerHeight();
			$(".top_slideshow__slide").css("min-height", (windowHeight - headerHeight));
		}
	}

// Add Commas to Input
// -----------------------------------------------------------------------------

	function numberWithCommas(x) {
		const parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}

	function addCommasOnKey() {
		$("input.number").keyup(function (event) {
			if ((event.which >= 37 && event.which <= 40) || event.which === 13) return;

			$(this).val(function (index, value) {
				$("input").removeClass("error");
				$(".error-message").hide();
				const valueAdjusted = value.replace(/,/g, "");
				return numberWithCommas(valueAdjusted);
			});
		});


	}


// Radio Button Change
// -----------------------------------------------------------------------------

	function radioChange() {
		$("input[type=radio]").change(function () {
			$(".option").removeClass("option--checked");
			$(this).parent().parent().addClass("option--checked");
			if ($(this).attr("name") === "applicants" && $(this).val() === "two") {
				$(".two-applicant-field").slideDown();
			} else {
				$(".two-applicant-field").slideUp();
			}
		});
	}

// Stamp Calculator
// -----------------------------------------------------------------------------

	function stampCalculator() {

		$("#submit-stamp").click(function () {
			let result;
			const values = {
				single: [0, 2, 5, 10, 12],
				additional: [3, 5, 8, 13, 15],
				first: [0, 2, 5, 10, 12],
			};

			const houseValue = parseFloat($("#house-value").val().replace(/,/g, ""));
			const property = $("input[name='property']:checked").val();

			if (isNaN(houseValue)) {
				$("#house-value").addClass("error");
				$(".error-message").show();
				$(".result-section").slideUp();
			} else {
				if (property === "first" && houseValue <= 500000) {
					if (houseValue > 300000) {
						result = ((houseValue - 300000) / 100) * 5;
					} else {
						result = 0;
					}
				} else {
					if (houseValue > 0) {
						result = (houseValue / 100) * values[property][0];
						result = result > ((125000 / 100) * values[property][0]) ? ((125000 / 100) * values[property][0]) : result;
					}
					if (houseValue > 125000) {
						result += (((houseValue - 125000) / 100) * values[property][1]);
						result = result > ((125000 / 100) * values[property][0] + (125000 / 100) * values[property][1]) ? ((125000 / 100) * values[property][0] + (125000 / 100) * values[property][1]) : result;
					}
					if (houseValue > 250000) {
						result += (((houseValue - 250000) / 100) * values[property][2]);
						result = result > ((125000 / 100) * values[property][0] + (125000 / 100) * values[property][1] + (675000 / 100) * values[property][2]) ? ((125000 / 100) * values[property][0] + (125000 / 100) * values[property][1] + (675000 / 100) * values[property][2]) : result;
					}
					if (houseValue > 925000) {
						result += (((houseValue - 925000) / 100) * values[property][3]);
						result = result > ((125000 / 100) * values[property][0] + (125000 / 100) * values[property][1] + (675000 / 100) * values[property][2] + (575000 / 100) * values[property][3]) ? ((125000 / 100) * values[property][0] + (125000 / 100) * values[property][1] + (675000 / 100) * values[property][2] + (575000 / 100) * values[property][3]) : result;
					}
					if (houseValue > 1500000) {
						result += (((houseValue - 1500000) / 100) * values[property][4]);
					}
				}

				result = result.toFixed(0);
				result = numberWithCommas(result);
				$(".stamp-result").text(result);
				$(".result-section").slideDown();
				$("html, body").animate({ scrollTop: ($(".result-section").offset().top - ($(".js-site-nav").css("visibility") === "hidden" ? $("#site-header").outerHeight() : $(".js-site-nav").outerHeight())) }, 1000);
			}
		});
	}

// Payment Calculator
// -----------------------------------------------------------------------------

	function PMT(ir, np, pv) {
		let pmt;
		const fv = 0;
		const type = 0;
		if (ir === 0) {
			return -(pv + fv) / np;
		}
		const pvif = Math.pow(1 + ir, np);
		pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);
		if (type === 1) {
			pmt /= (1 + ir);
		}
		return pmt;
	}

	function IPMT(rate, period, periods, present) {
		const interest = -present;
		return interest * rate;
	}

	function paymentCalculator() {
		$("#submit-payment").click(function () {
			let result;
			const houseValue = parseFloat($("#house-value").val().replace(/,/g, ""));
			if (isNaN(houseValue)) {
				$("#house-value").addClass("error");
			}
			const depositValue = parseFloat($("#deposit-value").val().replace(/,/g, ""));
			if (isNaN(depositValue)) {
				$("#deposit-value").addClass("error");
			}
			const mortgageValue = houseValue - depositValue;
			let mortgageTerm = $("#mortgage-term").val();
			if (isNaN(parseInt(mortgageTerm, 10))) {
				$("#mortgage-term").addClass("error");
			} else {
				mortgageTerm *= 12;
			}
			let interestRate = $("#interest-rate").val();
			if (isNaN(parseInt(interestRate, 10))) {
				$("#interest-rate").addClass("error");
			} else {
				interestRate /= 100;
				interestRate /= 12;
			}

			if ($("input[name='payment']:checked").val() === "repayment") {
				result = PMT(interestRate, mortgageTerm, mortgageValue);
			} else {
				result = IPMT(interestRate, 1, mortgageTerm, mortgageValue);
			}

			if ($("#house-value").hasClass("error") || $("#deposit-value").hasClass("error") || $("#mortgage-term").hasClass("error") || $("#interest-rate").hasClass("error")) {
				$(".error-message").show();
				$(".result-section").slideUp();
			} else {
				result = Math.abs(result);
				result = result.toFixed(2);
				result = numberWithCommas(result);

				$(".payment-result").text(result);
				$(".result-section").slideDown();
				$("html, body").animate({ scrollTop: ($(".result-section").offset().top - ($(".js-site-nav").css("visibility") === "hidden" ? $("#site-header").outerHeight() : $(".js-site-nav").outerHeight())) }, 1000);
			}
		});
	}

// Equity Release Calculator
// -----------------------------------------------------------------------------

	function equityCalculator() {

		const resultTable = [];

		// Populate the results table
		var vAge = 55, vLtv = 31;
		for (let i = 0; i < 60; i++) {
			var myVal = (vAge + i >= 60) ? (vLtv + i + 2): (vLtv + i); // Increments by one up until 60 when it's + 3
			myVal = (vAge + i >= 77) ? 55: myVal; // cap it at 55% for 77 onwards
			resultTable[vAge + i] = myVal;
		}

		/**
		 * Equity Release Calculator Form - Submit function
		 */
		$(document).on('click', '#submit-equity', function () {

			let result, // Result to output
				message = 'Please correct the following items:<ul>' // Error message
			;

			// Clear previous errors
			$(".error-message").html('');

			// Grab the form details
			const	name = $("#name").val(),
					phone = $("#phone").val(),
					age = $("#age").val(),
					email = $("#email").val(),
					propertyValue = parseFloat($("#property-value").val().replace(/,/g, ""))
			;

			// Got a property value?
			if (isNaN(propertyValue) || propertyValue < 70000) {
				$("#property-value").addClass("error");
				message += '<li>Please provide a property value (Minimum £70,000).</li>';
			}

			// Got an age?
			if (isNaN(age)) {
				$("#age").addClass("error");
				message += '<li>Please provide either a valid age over 55.</li>';
			} else if(age < 55 || age > 100) {
				$("#age").addClass("error");
				message += '<li>Please provide either a valid age over 55.</li>';
			}

			// Show errors or result?
			if (
				$("#property-value").hasClass("error")
				|| $("#age").hasClass("error")
			) {

				// Show any errors
				$(".error-message").html(message += '</ul>').show();
				$(".result-section").slideUp();

			} else {

				// Send the enquiry (if they provided details)
				if(phone != '' || email != '') {

					var form_data =  [
						{ name : 'age', value : $('#age').val() },
						{ name : 'name', value: $('#name').val() },
						{ name : 'phone', value : $('#phone').val() },
						{ name : 'email', value : $('#email').val() },
						{ name : 'value', value : $('#property-value').val() },
					];

					// Here we add our
					form_data.push( { "name" : "security", "value" : ajax_nonce } );

					// Add the action
					form_data.push( { "name" : "action", "value" : 'process_equity_form' } );

					$.ajax({
						url : ajax_url,
						type : 'post',
						data : form_data,
						success : function( response ) {
							if(response.status == '1') {

								// Show message(?)
								// $('#equity-calculator').fadeOut(1000, function () {
								// 	$('#equity-calculator').replaceWith(
								// 		'<div class="mb-4">' +
								// 		'<h2 class="mb-4">Enquiry received</h2>' +
								// 		'<p>A member of our team will be in touch to discuss your Equity Release options further.</p>' +
								// 		'</div>'
								// 	).fadeIn(1000);
								// });

							} else {

								// Pop errors in the error section
								$(".error-message").html('Please correct the following items:<ul>' + response.errors + '</ul>').show();
								$(".result-section").slideUp();

							}

						},
						fail : function( err ) {
							// Todo proper error resolution
							alert( "There was an error: " + err );
						}
					});

				}

				// Show the result
				var percentage = resultTable[age];
				var percentageValue = (propertyValue / 100) * percentage;
				var priceFormattedUK = new Intl.NumberFormat(
					'en-GB', // BCP 47 language tag
					{
						style: 'currency', // we want a currency
						currency: 'GBP', // ISO 4217 currency code
						maximumFractionDigits : 0,
						roundingMode: 'floor'
					}
				).format(percentageValue);

				$(".equity-result").text('Up to ' + priceFormattedUK);
				$(".result-section").slideDown();

				$(".error-message").html('');
				$("html, body").animate({ scrollTop: ($(".result-section").offset().top - ($(".js-site-nav").css("visibility") === "hidden" ? $("#site-header").outerHeight() : $(".js-site-nav").outerHeight())) }, 1000);

			}

		});
	}

// Loan Calculator
// -----------------------------------------------------------------------------

	function loanCalculator() {
		$("#submit-loan").click(function () {
			let incomeValue = (parseFloat($("#applicant-one").val().replace(/,/g, "")) > 0 ? parseFloat($("#applicant-one").val().replace(/,/g, "")) : 0);
			if (isNaN(parseFloat($("#applicant-one").val().replace(/,/g, "")))) {
				$("#applicant-one").addClass("error");
			}

			if ($("input[name='applicants']:checked").val() === "two") {
				incomeValue += (parseFloat($("#applicant-two").val().replace(/,/g, "")) > 0 ? parseFloat($("#applicant-two").val().replace(/,/g, "")) : 0);
				if (isNaN(parseFloat($("#applicant-two").val().replace(/,/g, "")))) {
					$("#applicant-two").addClass("error");
				}
			}

			let result = incomeValue * 4.45;
			result = result.toFixed(0);
			result = numberWithCommas(result);

			if ($("#applicant-one").hasClass("error") || $("#applicant-two").hasClass("error")) {
				$(".error-message").show();
				$(".result-section").slideUp();
			} else {
				$(".loan-result").text(result);
				$(".result-section").slideDown();
				$("html, body").animate({ scrollTop: ($(".result-section").offset().top - ($(".js-site-nav").css("visibility") === "hidden" ? $("#site-header").outerHeight() : $(".js-site-nav").outerHeight())) }, 1000);
			}
		});
	}

// Detect IE
// -----------------------------------------------------------------------------

	function detectIE() {
		const ua = window.navigator.userAgent;
		const msie = ua.indexOf("MSIE ");

		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
			$("html").addClass("is-ie");
		}

		return false;
	}

// Init
// -----------------------------------------------------------------------------

	$(document).ready(function () {
		toggleMenu();
		toggleDropdown();
		toggleAnswer();
		//toggleCallbackForm();
		initLazyLoad();
		initSlick();
		setSlideshowHeight();
		scrollToTop();
		toggleBlueSection();
		detectIE();
		stampCalculator();
		loanCalculator();
		paymentCalculator();
		equityCalculator();
		addCommasOnKey();
		radioChange();
		//initCookieBar();
		$(function () { objectFitImages(null, { watchMQ: true }); });
		w = window.outerWidth;
	});

	$(window).on("resize", function () {
		if (w !== window.outerWidth) {
			toggleDropdown();
			setSlideshowHeight();
			w = window.outerWidth;
		}
		if (window.matchMedia("(min-width: " + mdBreakpoint + "px)").matches === true) {
			$("html").removeClass("menu-is-open");
		}
	});

	// Scrolling request callback button
	$(document).ready(function () {
		var y = $(this).scrollTop(),
			el = $("#floating-callback");
		if($(document).width() < 798) {
			el.addClass("apparent");
		}
		$(window).resize(function () {
			if($(document).width() < 798) {
				el.addClass("apparent");
			} else {
				if (y > 240) {
					el.addClass("apparent");
				} else {
					el.removeClass("apparent");
				}
			}
		});
	}(jQuery));
	$(document).scroll(function() {
		var y = $(this).scrollTop(),
			el = $('#floating-callback');
		if($(document).width() > 797) {
			if (y > 240) {
				el.addClass('apparent');
			} else {
				el.removeClass('apparent');
			}
		}
	});
	// Close the dropdown
	$(document).on('click', '#floating-callback > a', function () {
		closeDropdown();
	});

}(jQuery));